<template>
  <v-layout column>
    <div class="align-center text-center" style="margin-top: 40px">
      <v-card elevation="6" max-width="1000" style="margin: auto">
        <v-toolbar dense flat class="grey lighten-4">
          <v-btn plain @click="changeToDevicesTable()">
            <v-icon>settings</v-icon>
            {{ $t("tables.viewDevices") }}
          </v-btn>
          <v-btn
            plain
            v-if="$store.state.isUserLoggedIn && userServices.advPortal"
            @click="changeToDeviceGroupPanel()"
          >
            <v-icon>settings</v-icon>
            {{ $t("tables.deviceGroups") }}
          </v-btn>
          <v-btn
            v-if="userRole === 'master' || userRole === 'admin'"
            plain
            @click="changeToOffsetPanel()"
          >
            <v-icon>settings</v-icon>
            {{ $t("tables.offsetSettings") }}
          </v-btn>
          <v-btn
            v-if="userRole === 'master' || userRole === 'admin'"
            plain
            @click="changeToRssiPanel()"
          >
            <v-icon>settings</v-icon>
            {{ $t("tables.rssi") }}
          </v-btn>
          <v-btn
            v-if="userRole === 'master' || userRole === 'admin'"
            plain
            @click="changeToUpdateFirmwarePanel()"
          >
            <v-icon>settings</v-icon>
            {{ $t("tables.updateDeviceFirmware") }}
          </v-btn>
        </v-toolbar>
      </v-card>
    </div>

    <!--DEVICE SETTINGS-->

    <!--VIEW DEVICES-->
    <v-container v-if="devicesInfo">
      <div class="align-center text-center" style="margin-top: -11px">
        <v-card elevation="6" max-width="1000" style="margin: auto">
          <v-toolbar flat dense class="blue darken-3" dark>
            <v-toolbar-title>{{ $t("tables.devicesList") }}</v-toolbar-title>
          </v-toolbar>

          <div v-if="$i18n.locale == 'en'" class="pl-4 pr-4 pt-2 pb-2">
            <v-data-table
              :headers="DeviceInfoHeaders"
              :search="search"
              :items="entries"
              :items-per-page="10"
              fixed-header
              class="elevation-1"
            >
              <template v-slot:[`item.isActive`]="{ item }">
                <td class="d-flex align-center">
                  <v-icon color="green darken-2" v-if="item.isActive"
                    >done</v-icon
                  >
                  <v-icon color="red darken-2" v-if="!item.isActive"
                    >close</v-icon
                  >
                </td>
              </template>

              <!--TABLE TEMPLATE - CRUD METHODS-->
              <template v-slot:top>
                <v-dialog v-model="dialog" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="4" md="4">
                            <v-text-field
                              v-model="editedItem.name"
                              :label="$t('global.name')"
                              :hint="$t('global.maximum16char')"
                              persistent-hint
                              autocomplete="off"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="8" md="8">
                            <v-text-field
                              v-model="editedItem.installationLocal"
                              :label="$t('global.installationLocal')"
                              :hint="$t('global.maximum16char')"
                              persistent-hint
                              autocomplete="off"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <div class="red--text" v-html="deviceInfoError" />

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-3" text @click="close">
                        Cancel
                      </v-btn>
                      <v-btn color="blue darken-3" text @click="save">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                  edit
                </v-icon>
              </template>
            </v-data-table>

            <v-row>
              <v-col cols="8"> </v-col>

              <v-col cols="4" class="mt-2 pt-0" style="">
                <v-card-title>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('tables.search')"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-card-title>
              </v-col>
            </v-row>
          </div>

          <div v-if="$i18n.locale == 'pt'" class="pl-4 pr-4 pt-2 pb-2">
            <v-data-table
              :headers="DeviceInfoHeadersPt"
              :search="search"
              :items="entries"
              :items-per-page="10"
              fixed-header
              class="elevation-1"
            >
              <template v-slot:[`item.isActive`]="{ item }">
                <td class="d-flex align-center">
                  <v-icon color="green darken-2" v-if="item.isActive"
                    >done</v-icon
                  >
                  <v-icon color="red darken-2" v-if="!item.isActive"
                    >close</v-icon
                  >
                </td>
              </template>

              <!--TABLE TEMPLATE - CRUD METHODS-->
              <template v-slot:top>
                <v-dialog v-model="dialog" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="4" md="4">
                            <v-text-field
                              v-model="editedItem.name"
                              :label="$t('global.name')"
                              :hint="$t('global.maximum16char')"
                              persistent-hint
                              autocomplete="off"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="8" md="8">
                            <v-text-field
                              v-model="editedItem.installationLocal"
                              :label="$t('global.installationLocal')"
                              :hint="$t('global.maximum16char')"
                              persistent-hint
                              autocomplete="off"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <div class="red--text" v-html="deviceInfoError" />

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-3" text @click="close">
                        Cancelar
                      </v-btn>
                      <v-btn color="blue darken-3" text @click="save">
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                  editar
                </v-icon>
              </template>
            </v-data-table>

            <v-row>
              <v-col cols="8"> </v-col>

              <v-col cols="4" class="mt-2 pt-0" style="">
                <v-card-title>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('tables.search')"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-card-title>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>
    </v-container>

    <!--OFFSETS-->
    <v-container v-if="offsetSettings">
      <div class="align-center text-center" style="margin-top: -10px">
        <v-card elevation="6" max-width="1000" style="margin: auto">

          <!--SET OFFSETS-->
          <v-toolbar flat dense class="blue darken-3" dark>
            <v-toolbar-title>{{ $t("tables.setOffsets") }}</v-toolbar-title>
          </v-toolbar>

          <div class="d-flex mt-4">
            <v-flex class="md3 ml-4">
              <v-container fluid>
                <v-select
                  class=""
                  v-model="deviceName"
                  :items="devices"
                  :search-input.sync="search"
                  hide-selected
                  :hint="$t('global.maximum1Value')"
                  :label="$t('global.device')"
                  persistent-hint
                  small-chips
                  outlined
                >
                </v-select>
              </v-container>
            </v-flex>

            <v-flex class="md3 ml-4" v-if="$i18n.locale == 'en'">
              <v-container fluid>
                <v-combobox
                  class=""
                  v-model="dataType"
                  :items="dataTypes"
                  :search-input.sync="search"
                  hide-selected
                  :hint="$t('global.maximum1Value')"
                  :label="$t('global.dataType')"
                  persistent-hint
                  multiple
                  small-chips
                  outlined
                >
                </v-combobox>
              </v-container>
            </v-flex>

            <v-flex class="md3 ml-4" v-if="$i18n.locale == 'pt'">
              <v-container fluid>
                <v-combobox
                  class=""
                  v-model="dataType"
                  :items="dataTypes"
                  :search-input.sync="search"
                  hide-selected
                  :hint="$t('global.maximum1Value')"
                  :label="$t('global.dataType')"
                  persistent-hint
                  multiple
                  small-chips
                  outlined
                >
                </v-combobox>
              </v-container>
            </v-flex>

            <v-flex class="md2 mt-3 ml-6">
              <v-text-field
                v-model="value"
                :label="$t('global.offset')"
                autocomplete="off"
                filled
                outlined
                backgroundColor="white"
              ></v-text-field>
            </v-flex>

            <v-btn
              elevation="2"
              min-width="100px"
              class="ml-16 mt-5 mr-6"
              @click="setDeviceOffsets"
            >
              {{ $t("button.save") }}
            </v-btn>
          </div>

          <br />
          <div class="red--text" v-html="deviceError" />
          <div class="green--text" v-html="deviceSuccess" />
        </v-card>
      </div>

      <!--VIEW OFFSETS-->
      <div class="align-center text-center" style="margin-top: 0px">
        <v-card elevation="6" max-width="1000" style="margin: auto">
          <v-toolbar color="blue darken-3" dark flat dense>
            <v-toolbar-title>{{ $t("tables.viewOffsets") }}</v-toolbar-title>
          </v-toolbar>
        </v-card>

        <v-card elevation="6" max-width="1000" style="margin: auto">
          <v-container class="align-center text-center mx-0 sm">
            <v-row>
              <v-col>
                <v-flex class="md6 ml-1">
                  <v-container fluid>
                    <v-select
                      class=""
                      v-model="deviceName1"
                      :items="devices"
                      :search-input.sync="search"
                      hide-selected
                      :hint="$t('global.maximum1Value')"
                      :label="$t('global.device')"
                      persistent-hint
                      small-chips
                      outlined
                    >
                    </v-select>
                  </v-container>
                </v-flex>

                <v-btn
                  elevation="2"
                  min-width="100px"
                  class="ml2 mt-3 mr-2"
                  @click="getDeviceOffsets()"
                >
                  {{ $t("button.view") }}
                </v-btn>
              </v-col>

              <v-col>
                <v-data-iterator
                  :items="deviceOffsets"
                  :items-per-page="1"
                  hide-default-footer
                >
                  <template v-slot:default="props">
                    <v-row>
                      <v-col
                        v-for="item in props.items"
                        :key="item.deviceName"
                        cols="12"
                        sm="11"
                        md="11"
                        lg="11"
                      >
                        <v-card>
                          <v-card-title
                            v-if="deviceOffsets[0].deviceName != undefined"
                            class="subheading font-weight-bold"
                          >
                            {{ deviceOffsets[0].deviceName + " " + "Offsets" }}
                          </v-card-title>

                          <v-card-title
                            v-if="deviceOffsets[0].deviceName == undefined"
                            class="subheading font-weight-bold"
                          >
                            {{ $t("titles.deviceOffsets") }}
                          </v-card-title>

                          <v-divider></v-divider>

                          <v-list dense>
                            <v-list-item>
                              <v-list-item-content
                                >{{
                                  $t("dashboard.temperature")
                                }}:</v-list-item-content
                              >
                              <v-list-item-content class="align-end">
                                {{ item.temperature }}
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-content
                                >{{
                                  $t("dashboard.carbonDioxide")
                                }}:</v-list-item-content
                              >
                              <v-list-item-content class="align-end">
                                {{ item.co2 }}
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-content
                                >{{
                                  $t("dashboard.noise")
                                }}:</v-list-item-content
                              >
                              <v-list-item-content class="align-end">
                                {{ item.noise }}
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-content
                                >{{
                                  $t("dashboard.humidity")
                                }}:</v-list-item-content
                              >
                              <v-list-item-content class="align-end">
                                {{ item.humidity }}
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-content
                                >{{
                                  $t("dashboard.lightIntensity")
                                }}:</v-list-item-content
                              >
                              <v-list-item-content class="align-end">
                                {{ item.lightIntensity }}
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-content
                                >{{
                                  $t("dashboard.airQuality")
                                }}:</v-list-item-content
                              >
                              <v-list-item-content class="align-end">
                                {{ item.airQuality }}
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-content
                                >{{
                                  $t("dashboard.atmosphericPressure")
                                }}:</v-list-item-content
                              >
                              <v-list-item-content class="align-end">
                                {{ item.pressure }}
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-content
                                >{{
                                  $t("dashboard.wifiSignal")
                                }}:</v-list-item-content
                              >
                              <v-list-item-content class="align-end">
                                {{ item.wifi }}
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item v-if="userServices.cards12">
                              <v-list-item-content
                                >{{
                                  $t("dashboard.lightTemperature")
                                }}:</v-list-item-content
                              >
                              <v-list-item-content class="align-end">
                                {{ item.lightTemperature }}
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item v-if="userServices.cards12">
                              <v-list-item-content
                                >{{
                                  $t("dashboard.numberOfDevices")
                                }}:</v-list-item-content
                              >
                              <v-list-item-content class="align-end">
                                {{ item.numberOfDevices }}
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-content
                                >{{
                                  $t("global.dateUpdated")
                                }}:</v-list-item-content
                              >
                              <v-list-item-content
                                v-if="deviceOffsets[0].updatedAt != undefined"
                                class="align-end"
                              >
                                {{
                                  moment(item.updatedAt).format(
                                    "MMMM Do YYYY, h:mm:ss a"
                                  )
                                }}
                              </v-list-item-content>
                              <v-list-item-content
                                v-if="deviceOffsets[0].updatedAt == undefined"
                                class="align-end"
                              >
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-content
                                >{{
                                  $t("dashboard.firmwareUpdated")
                                }}:</v-list-item-content
                              >
                              <v-list-item-content
                                v-if="item.firmwareUpdated"
                                class="align-end"
                              >
                                {{ "✔" }}
                              </v-list-item-content>
                              <v-list-item-content
                                v-if="!item.firmwareUpdated"
                                class="align-end"
                              >
                                {{ "✘" }}
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </v-col>
            </v-row>
          </v-container>

          <!--UPDATE DEVICE OFFSETS-->

          <v-toolbar flat dense class="blue darken-3" dark>
            <v-toolbar-title>{{
              $t("tables.updateDeviceOffsets")
            }}</v-toolbar-title>
          </v-toolbar>

          <div class="d-flex mt-4 mb-10">
            <v-flex class="md3 ml-4">
              <v-container fluid>
                <v-select
                  class=""
                  v-model="deviceName2"
                  :items="devices"
                  :search-input.sync="search"
                  hide-selected
                  :hint="$t('global.maximum1Value')"
                  :label="$t('global.device')"
                  persistent-hint
                  small-chips
                  outlined
                >
                </v-select>
              </v-container>
            </v-flex>

            <v-btn
              elevation="2"
              class="ml-16 mt-5 mr-6"
              min-width="100px"
              @click="updateDeviceOffsets"
            >
              {{ $t("button.update") }}
            </v-btn>

            <!-- <div class="red--text ml-16" v-html="updateError" /> -->
            <div class="black--text ml-16" v-html="firmwareUpdateMessage" />
          </div>
        </v-card>
      </div>
    </v-container>

    <!--RSSI SETTINGS-->
    <v-container v-if="rssiSettings">
      <div class="align-center text-center" style="margin-top: -10px">
        <v-card elevation="6" max-width="1000" style="margin: auto">

          <!--SET DEVICE RSSI THRESHOLD-->
          <v-toolbar flat dense class="blue darken-3" dark>
            <v-toolbar-title>{{
              $t("tables.setDeviceRssiThreshold")
            }}</v-toolbar-title>
          </v-toolbar>

          <div class="d-flex mt-4">
            <v-flex class="md3 ml-4">
              <v-container>
                <v-select
                  class=""
                  background-color="#FAFAFA"
                  :items="devices"
                  v-model="rssiSelectedDevice"
                  :label="$t('global.device')"
                  :hint="$t('global.maximum1Value')"
                  persistent-hint
                  hide-selected
                  small-chips
                  outlined
                ></v-select>
              </v-container>
            </v-flex>

            <v-flex class="md2 mt-3 ml-6">
              <v-text-field
                v-model="rssiValue"
                :label="$t('global.rssiThresholdValue')"
                autocomplete="off"
                filled
                outlined
                backgroundColor="white"
              ></v-text-field>
            </v-flex>

            <v-btn
              elevation="2"
              min-width="100px"
              class="ml-16 mt-5 mr-0"
              @click="saveRssiConfig"
            >
              {{ $t("button.setRssiThreshold") }}
            </v-btn>
          </div>
          <div>
            <v-btn
              width="150"
              class="mt-2"
              elevation="2"
              @click="navigateTo({ name: 'rssiInfo' })"
            >
              {{ $t("button.moreInfo") }}
            </v-btn>
          </div>

          <br />
          <div class="green--text" v-html="rssiUpdateMessage" />
        </v-card>

        
        <!--VIEW DEVICE RSSI THRESHOLD-->

        <div class="align-center text-center" style="margin-top: 0px">
        <v-card elevation="6" max-width="1000" style="margin: auto">
          <v-toolbar color="blue darken-3" dark flat dense>
            <v-toolbar-title>{{ $t("tables.viewRssi") }}</v-toolbar-title>
          </v-toolbar>
        </v-card>

        <v-card elevation="6" max-width="1000" style="margin: auto">
          <v-container class="align-center text-center mx-0 sm">
            <v-row>
              <v-col>
                <v-flex class="md6 ml-1">
                  <v-container fluid>
                    <v-select
                      class=""
                      v-model="rssiSelectedDevice1"
                      :items="devices"
                      :search-input.sync="search"
                      hide-selected
                      :hint="$t('global.maximum1Value')"
                      :label="$t('global.device')"
                      persistent-hint
                      small-chips
                      outlined
                    >
                    </v-select>
                  </v-container>
                </v-flex>

                <v-btn
                  elevation="2"
                  min-width="100px"
                  class="ml2 mt-3 mr-2"
                  @click="getDeviceRssi()"
                >
                  {{ $t("button.view") }}
                </v-btn>
              </v-col>

              <v-col>
                <v-data-iterator
                  :items="deviceRssi"
                  :items-per-page="1"
                  hide-default-footer
                >
                  <template v-slot:default="props">
                    <v-row>
                      <v-col
                        v-for="item in props.items"
                        :key="item.deviceName"
                        cols="12"
                        sm="11"
                        md="11"
                        lg="11"
                      >
                        <v-card>
                          <v-card-title
                            v-if="deviceRssi[0].deviceName != undefined"
                            class="subheading font-weight-bold"
                          >
                            {{ deviceRssi[0].deviceName + " " + "RSSI Threshold" }}
                          </v-card-title>

                          <v-card-title
                            v-if="deviceRssi[0].deviceName == undefined"
                            class="subheading font-weight-bold"
                          >
                            {{ $t("titles.deviceRSSI") }}
                          </v-card-title>

                          <v-divider></v-divider>

                          <v-list dense>
                            <v-list-item>
                              <v-list-item-content
                                >{{
                                  $t("dashboard.rssiThreshold")
                                }}:</v-list-item-content
                              >
                              <v-list-item-content class="align-end">
                                {{ item.rssi }}
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-content
                                >{{
                                  $t("dashboard.rssiUpdated")
                                }}:</v-list-item-content
                              >
                              <v-list-item-content
                                v-if="item.rssiUpdated"
                                class="align-end"
                              >
                                {{ "✔" }}
                              </v-list-item-content>
                              <v-list-item-content
                                v-if="!item.rssiUpdated"
                                class="align-end"
                              >
                                {{ "✘" }}
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-content
                                >{{
                                  $t("dashboard.rssiupdatedAt")
                                }}:</v-list-item-content
                              >
                              <v-list-item-content class="align-end">
                                {{ item.updatedAt }}
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </v-col>
            </v-row>
          </v-container>

          <!--UPDATE DEVICE RSSI-->

          <v-toolbar flat dense class="blue darken-3" dark>
            <v-toolbar-title>{{
              $t("tables.updateDeviceRssi")
            }}</v-toolbar-title>
          </v-toolbar>

          <div class="d-flex mt-4 mb-10">
            <v-flex class="md3 ml-4">
              <v-container fluid>
                <v-select
                  class=""
                  v-model="rssiSelectedDevice2"
                  :items="devices"
                  :search-input.sync="search"
                  hide-selected
                  :hint="$t('global.maximum1Value')"
                  :label="$t('global.device')"
                  persistent-hint
                  small-chips
                  outlined
                >
                </v-select>
              </v-container>
            </v-flex>

            <v-btn
              elevation="2"
              class="ml-16 mt-5 mr-6"
              min-width="100px"
              @click="updateDeviceRssi"
            >
              {{ $t("button.update") }}
            </v-btn>

            <!-- <div class="red--text ml-16" v-html="updateError" /> -->
            <div class="black--text ml-16" v-html="firmwareUpdateMessage" />
          </div>
        </v-card>
      </div>
        
      </div>
    </v-container>

    <!--DEVICE GROUPS-->
    <v-container v-if="deviceGroupSettings">
      <div class="align-center text-center" style="margin-top: -10px">
        <v-card elevation="6" max-width="1000" style="margin: auto">
          <!--CREATE GROUP-->

          <v-toolbar flat dense class="blue darken-3" dark>
            <v-toolbar-title>{{ $t("tables.createGroup") }}</v-toolbar-title>
          </v-toolbar>

          <div class="d-flex mt-6">
            <v-flex class="md6 ml-6">
              <v-text-field
                v-model="groupName"
                :label="$t('global.groupName')"
                autocomplete="off"
                filled
                outlined
                backgroundColor="white"
              ></v-text-field>
            </v-flex>

            <br />

            <v-btn
              elevation="2"
              min-width="100px"
              class="ml-16 mt-3 mr-6"
              @click="createDeviceGroup"
            >
              {{ $t("button.create") }}
            </v-btn>
          </div>

          <br />
          <div class="red--text" v-html="groupError" />
          <div class="green--text" v-html="groupSuccess" />
        </v-card>
      </div>

      <div class="align-center text-center" style="margin-top: 0px">
        <v-card elevation="6" max-width="1000" style="margin: auto">
          <v-toolbar color="blue darken-3" dark flat dense>
            <v-toolbar-title>{{ $t("tables.groupDevices") }}</v-toolbar-title>
          </v-toolbar>
        </v-card>

        <v-card elevation="6" max-width="1000" style="margin: auto">
          <v-container class="align-center text-center mx-0 sm">
            <v-row>
              <v-col class="">
                <v-flex class="md6 ml-0">
                  <v-container fluid>
                    <v-select
                      class=""
                      v-model="groupName1"
                      :items="deviceGroups"
                      :search-input.sync="search"
                      hide-selected
                      :hint="$t('global.maximum1Value')"
                      :label="$t('global.groupName')"
                      persistent-hint
                      small-chips
                      outlined
                    >
                    </v-select>
                  </v-container>
                </v-flex>

                <v-btn
                  elevation="2"
                  min-width="150px"
                  class="mr-16 mt-5 mr-2"
                  @click="deleteDeviceGroup()"
                >
                  {{ $t("button.deleteGroup") }}
                </v-btn>

                <v-btn
                  elevation="2"
                  min-width="100px"
                  class="ml2 mt-5 mr-2"
                  @click="getDevicesFromGroup()"
                >
                  {{ $t("button.view") }}
                </v-btn>

                <div class="red--text ml-16" v-html="deleteGroupError" />
                <div class="green--text ml-16" v-html="deleteGroupSuccess" />
              </v-col>

              <v-col class="mr-2" v-if="$i18n.locale == 'en'">
                <v-data-table
                  :headers="GroupHeaders"
                  :items="devicesFromGroup"
                  :items-per-page="5"
                  class="elevation-1"
                ></v-data-table>
              </v-col>
              <v-col class="mr-2" v-if="$i18n.locale == 'pt'">
                <v-data-table
                  :headers="GroupHeadersPt"
                  :items="devicesFromGroup"
                  :items-per-page="5"
                  class="elevation-1"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-container>

          <!--ADD DEVICE TO GROUP-->

          <v-toolbar flat dense class="blue darken-3" dark>
            <v-toolbar-title>{{ $t("tables.addToGroup") }}</v-toolbar-title>
          </v-toolbar>

          <div class="d-flex mt-4 mb-4">
            <v-flex class="md3 ml-4">
              <v-container fluid>
                <v-select
                  class=""
                  v-model="groupName2"
                  :items="deviceGroups"
                  :search-input.sync="search"
                  hide-selected
                  :hint="$t('global.maximum1Value')"
                  :label="$t('global.groupName')"
                  persistent-hint
                  small-chips
                  outlined
                >
                </v-select>
              </v-container>
            </v-flex>

            <v-flex class="md3 ml-4">
              <v-container fluid>
                <v-select
                  class=""
                  v-model="deviceName4"
                  :items="devices"
                  :search-input.sync="search"
                  hide-selected
                  :hint="$t('global.maximum1Value')"
                  :label="$t('global.device')"
                  persistent-hint
                  small-chips
                  outlined
                >
                </v-select>
              </v-container>
            </v-flex>

            <v-btn
              elevation="2"
              min-width="100px"
              class="ml-16 mt-5 mr-6"
              @click="addDeviceToGroup"
            >
              {{ $t("button.add") }}
            </v-btn>

            <div class="red--text ml-16" v-html="addDeviceError" />
            <div class="green--text ml-16" v-html="addDeviceSuccess" />
          </div>

          <v-toolbar flat dense class="blue darken-3" dark>
            <v-toolbar-title>{{
              $t("tables.removeFromGroup")
            }}</v-toolbar-title>
          </v-toolbar>

          <div class="d-flex mt-4 mb-10">
            <v-flex class="md3 ml-4">
              <v-container fluid>
                <v-select
                  class=""
                  v-model="groupName3"
                  :items="deviceGroups"
                  :search-input.sync="search"
                  hide-selected
                  :hint="$t('global.maximum1Value')"
                  :label="$t('global.groupName')"
                  persistent-hint
                  small-chips
                  outlined
                >
                </v-select>
              </v-container>
            </v-flex>

            <v-flex class="md3 ml-4">
              <v-container fluid>
                <v-select
                  class=""
                  v-model="deviceName5"
                  :items="devices"
                  :search-input.sync="search"
                  hide-selected
                  :hint="$t('global.maximum1Value')"
                  :label="$t('global.device')"
                  persistent-hint
                  small-chips
                  outlined
                >
                </v-select>
              </v-container>
            </v-flex>

            <v-btn
              elevation="2"
              min-width="100px"
              class="ml-16 mt-5 mr-6"
              @click="removeDeviceFromGroup"
            >
              {{ $t("button.remove") }}
            </v-btn>

            <div class="red--text ml-16" v-html="removeDeviceError" />
            <div class="green--text ml-16" v-html="removeDeviceSuccess" />
          </div>
        </v-card>
      </div>
    </v-container>

    <!-- UPDATE DEVICE -->
    <v-container v-if="firmwareUpdate">
      <div class="align-center text-center" style="margin-top: -10px">
        <v-card elevation="6" max-width="1000" style="margin: auto">
          <!--UPDATE DEVICE FIRMWARE-->

          <v-toolbar flat dense class="blue darken-3" dark>
            <v-toolbar-title>{{
              $t("tables.updateDeviceFirmware2")
            }}</v-toolbar-title>
          </v-toolbar>

          <div class="d-flex mt-4">
            <v-flex class="md6 ml-6">
              <v-row>
                <v-col>
                  <v-select
                    style="width: 14vw"
                    background-color="#FAFAFA"
                    class="ml-12 mr-0 mt-2"
                    :items="devices"
                    v-model="selectedDevice"
                    :label="$t('button.chooseDevice')"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
            </v-flex>

            <br />

            <v-btn
              elevation="2"
              min-width="200px"
              class="ml-0 mt-4 mr-0"
              @click="updateFirmware"
            >
              {{ $t("button.updateFirmware") }}
            </v-btn>
          </div>

          <br />
          <div class="black--text" v-html="firmwareUpdateMessage" />
        </v-card>
      </div>
    </v-container>
  </v-layout>
</template>

<script>
import DeviceGroupService from "@/services/DeviceGroupService";
import DeviceService from "@/services/DeviceService";
import DataService from "@/services/DataService";
import AppService from "@/services/AppService";
import HelperMethods from "@/utilities/HelperMethods";
import moment from "moment";
import { mapState } from "vuex";
import io from "socket.io-client";
const config = require("@/config/config");

export default {
  data() {
    return {
      //Global variables
      groupDevices: [],
      devicesFromGroup: [],
      deviceGroups: [],
      groupDeviceGroups: [],
      devices: [],
      userInfo: {},
      userServices: {},
      devicesInfo: true,
      deviceGroupSettings: false,
      offsetSettings: false,
      rssiSettings: false,
      firmwareUpdate: false,
      selectedDevice: null,

      //v-data-table template variables
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        name: "",
        installationLocal: "",
      },
      defaultItem: {
        name: "",
        installationLocal: "",
      },

      //View Devices variables
      DeviceInfoHeaders: [
        { text: "Name", value: "name" },
        { text: "Installation Local", value: "installationLocal" },
        { text: "Mac Address", value: "mac" },
        { text: "Serial Number", value: "serialNumber" },
        { text: "Version", value: "version" },
        { text: "Date Added", value: "createdAt" },
        { text: "Status", value: "isActive" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      DeviceInfoHeadersPt: [
        { text: "Nome", value: "name" },
        { text: "Local de Instalação", value: "installationLocal" },
        { text: "Endereço Mac", value: "mac" },
        { text: "Número de Série", value: "serialNumber" },
        { text: "Versão", value: "version" },
        { text: "Data de Instalação", value: "createdAt" },
        { text: "Estado", value: "isActive" },
        { text: "Acções", value: "actions", sortable: false },
      ],
      entries: [],
      deviceIds: [],
      deviceId: "",
      chosenDeviceId: "",
      name: "",
      installLocal: "",
      deviceInfoError: null,
      deviceInfoSuccess: null,

      //Device Group variables
      GroupHeaders: [
        {
          text: "Device",
          align: "start",
          value: "deviceName",
        },
        { text: "Date Added", value: "createdAt" },
      ],
      GroupHeadersPt: [
        {
          text: "Dispositivo",
          align: "start",
          value: "deviceName",
        },
        { text: "Adicionado em", value: "createdAt" },
      ],
      groupName: "",
      groupName1: "",
      groupName2: "",
      groupName3: "",
      deviceName4: "",
      deviceName5: "",
      addDeviceError: null,
      addDeviceSuccess: null,
      removeDeviceSuccess: null,
      removeDeviceError: null,
      groupSuccess: null,
      groupError: null,
      deleteGroupSuccess: null,
      deleteGroupError: null,

      //Offset Settings variables
      deviceOffsets: [{}],
      groupOffsets: [],
      dataTypes: [
        "Temperature",
        "Carbon Dioxide",
        "Noise",
        "Humidity",
        "Light Intensity",
        "Air Quality",
        "Pressure",
        "Wifi Signal",
        "Light Temperature",
        "Number of Devices",
      ],
      dataTypesPt: [
        "Temperatura",
        "Dióxido de Carbono",
        "Ruído",
        "Humidade",
        "Intensidade da Luz",
        "Qualidade do Ar",
        "Pressão",
        "Sinal Wifi",
        "Temperatura da Luz",
        "Número de Dispositivos",
      ],
      deviceName: "",
      deviceName1: "",
      deviceName2: "",
      deviceName3: "",
      groupId: null,
      value: null,
      dataType: null,
      search: null,
      deviceError: null,
      deviceSuccess: null,
      updateError: null,

      //Rssi Settings variables
      deviceRssi: [{}],
      rssiValue: null,
      rssiUpdateMessage: null,
      rssiSelectedDevice: "",
      rssiSelectedDevice1: "",
      rssiSelectedDevice2: "",


      // Socket.io variables
      socket: null,

      // Firmware Update variables
      firmwareUpdateMessage: null,
    };
  },

  computed: {
    ...mapState(["userRole"]),
    formTitle() {
      if (this.$i18n.locale == "pt") {
        return this.editedIndex === -1 ? "Nova Entrada" : "Editar Entrada";
      } else return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  methods: {
    // Global Methods
    convertDeviceNameToId(deviceName) {
      for (let i = 0; i < this.groupDevices.length; i++) {
        if (deviceName == this.groupDevices[i].name) {
          return this.groupDevices[i].id;
        }
      }
    },

    changeToDevicesTable() {
      if (this.devicesInfo == false) {
        this.devicesInfo = true;
        this.offsetSettings = false;
        this.rssiSettings = false;
        this.deviceGroupSettings = false;
        this.firmwareUpdate = false;
      } else return;
    },

    changeToDeviceGroupPanel() {
      if (this.deviceGroupSettings == false) {
        this.deviceGroupSettings = true;
        this.offsetSettings = false;
        this.rssiSettings = false;
        this.devicesInfo = false;
        this.firmwareUpdate = false;
      } else return;
    },

    changeToOffsetPanel() {
      this.clearLog();
      if (this.offsetSettings == false) {
        this.offsetSettings = true;
        this.rssiSettings = false;
        this.deviceGroupSettings = false;
        this.devicesInfo = false;
        this.firmwareUpdate = false;
      } else return;
    },

    changeToRssiPanel() {
      this.clearLog();
      if (this.rssiSettings == false) {
        this.rssiSettings = true;
        this.firmwareUpdate = false;
        this.offsetSettings = false;
        this.deviceGroupSettings = false;
        this.devicesInfo = false;
      } else return;
    },

    changeToUpdateFirmwarePanel() {
      this.clearLog();
      if (this.firmwareUpdate == false) {
        this.firmwareUpdate = true;
        this.offsetSettings = false;
        this.rssiSettings = false;
        this.deviceGroupSettings = false;
        this.devicesInfo = false;
      } else return;
    },

    // View Devices methods

    async retrieveEntries() {
      let self = this;

      try {
        const response = await DeviceService.getGroupDevices(this.groupId);
        self.entries = response.data;
        self.entries = self.entries.map((entry) => {
          entry["isActive"] = null;
          return entry;
        });
      } catch (err) {
        console.log(err);
        return;
      }

      this.entries = this.entries.map((entry) => {
        this.deviceIds.push(entry.id);
        entry.createdAt = HelperMethods.formatTimestamp4(entry.createdAt);
        return entry;
      });

      // Below code is used to get all group devices last timestamp at data table to compare
      // to the actual time and sample ratio and determine if the device is active or offline
      // Takes an array of device objects as the argument

      let sampleRate = this.$store.state.userServices;
      var sr = null;

      if (sampleRate.sr5 === true) {
        sr = 320;
      } else if (sampleRate.sr10 === true) {
        sr = 620;
      } else if (sampleRate.sr20 === true) {
        sr = 1220;
      }

      let ids = [];
      for (let i = 0; i < this.entries.length; i++) {
        ids.push(self.entries[i].id);
      }
      try {
        let response = await DataService.getAllDevicesCurrentData(ids);

        self.entries = self.entries.map((entry) => {
          response.data.map((r) => {
            if (entry.id == r.refDeviceId) {
              let startTime = moment(r.createdAt);
              let endTime = moment();
              if (endTime.diff(startTime, "seconds") >= sr) {
                entry["isActive"] = false;
              } else {
                entry["isActive"] = true;
              }
            }
            return r;
          });
          return entry;
        });
      } catch (err) {
        console.log(err);
      }
    },

    refreshList() {
      this.retrieveEntries();
    },

    editItem(item) {
      this.editedIndex = this.entries.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.updateDeviceInfo(this.editedItem);
    },

    async updateDeviceInfo(editedItem) {
      if (this.$i18n.locale === "en") {
        if (editedItem.name.length > 16) {
          this.deviceInfoError = "Name length must not exceed 16 characters.";
          return;
        } else if (editedItem.installationLocal.length > 16) {
          this.deviceInfoError =
            "Installation Local must not exceed 16 characters.";
          return;
        } else if (
          editedItem.name.length < 1 ||
          editedItem.installationLocal.length < 1
        ) {
          this.deviceInfoError = "Ambos os campos precisam estar preenchidos.";
          return;
        }
      } else if (this.$i18n.locale === "pt") {
        if (editedItem.name.length > 16) {
          this.deviceInfoError = "O nome deve ter um máximo de 16 caracteres.";
          return;
        } else if (editedItem.installationLocal.length > 16) {
          this.deviceInfoError =
            "O local de instalação deve ter um máximo de 16 caracteres.";
          return;
        } else if (
          editedItem.name.length < 1 ||
          editedItem.installationLocal.length < 1
        ) {
          this.deviceInfoError = "Both fields must be filled.";
          return;
        }
      }

      let obj = {
        id: editedItem.id,
        name: editedItem.name,
        installLocal: editedItem.installationLocal,
      };

      try {
        const response = await DeviceService.updateDeviceInfo(obj);
        this.deviceInfoError = null;
        this.deviceInfoSuccess = "Device info updated successfully.";
        this.refreshList();
        console.log("Response: ", response);

        const response1 = await AppService.addUserAction({
          userId: this.userInfo.id,
          userRole: this.userInfo.refRole,
          message: `edit device id ${response.data.device.id} info`,
        });
        console.log("Response1: ", response1);
      } catch (err) {
        this.deviceInfoError = err.response.data.error;
        console.log(err);
      }
      this.close();
    },

    // Device Offsets methods

    async updateDeviceOffsets() {
      let deviceId = this.convertDeviceNameToId(String(this.deviceName2));
      let deviceObj = HelperMethods.getDeviceObjByName(
        this.groupDevices,
        this.deviceName2
      );

      try {
        const response = await DeviceService.getDevice(deviceId);
        let mac = response.data.mac;

        const response1 = await DeviceService.getDeviceOffsets(deviceId);
        let offsets = response1.data[0];

        let offsetsConfigMessage =
          HelperMethods.mqttUpdateOffsetsMessageBuilder(offsets, mac);

        this.socket.emit("deviceSettings", {
          type: "updateDeviceOffsets",
          data: {
            offsetsMessage: offsetsConfigMessage,
            device: deviceObj,
          },
        });

        let response2 = await DeviceService.setOffsetsUpdatedStatus({
          refDevice: deviceId,
          status: true,
        });
        console.log("response2:", response2);

        setTimeout(this.waitLog, 1000);
        setTimeout(this.failureLog, 8000);

        this.updateError = null;
      } catch (err) {
        this.updateError =
          "Device offsets update failed. <br/>Try again in a few moments.";
        console.log(err);
      }
    },

    async setDeviceOffsets() {
      let deviceId = this.convertDeviceNameToId(String(this.deviceName));
      let dataType = HelperMethods.getDataType(this.dataType[0]);

      try {
        const response = await DeviceService.setDeviceOffsets({
          deviceId: deviceId,
          dataType: dataType,
          value: this.value,
        });

        const response1 = await DeviceService.setOffsetsUpdatedStatus({
          refDevice: deviceId,
          status: false,
        });
        this.deviceError = null;
        this.deviceSuccess = "Offset successfully updated.";
        console.log("response: ", response.data);
        console.log("response1: ", response1);
      } catch (err) {
        this.deviceError = "An error occurred while updating the offset.";
        console.log(err);
      }
    },

    async getDeviceOffsets() {
      let self = this;
      let deviceId = this.convertDeviceNameToId(String(this.deviceName1));

      try {
        const response = await DeviceService.getDeviceOffsets(deviceId);
        this.deviceOffsets = response.data;
        if (this.deviceOffsets.length === 0) {
          return (this.deviceOffsets = [
            {
              deviceName: this.deviceName1,
              temperature: 0,
              co2: 0,
              noise: 0,
              humidity: 0,
              lightIntensity: 0,
              airQuality: 0,
              pressure: 0,
              wifi: 0,
              lightTemperature: 0,
              numberOfDevices: 0,
              updatedAt: null,
              createdAt: 0,
              firmwareUpdated: false,
            },
          ]);
        } else {
          for (let i = 0; i < this.groupDevices.length; i++) {
            if (this.groupDevices[i].id == this.deviceOffsets[0].refDevice) {
              self.deviceOffsets[0].deviceName = self.groupDevices[i].name;
              break;
            }
          }
        }
        console.log("response: ", this.deviceOffsets);
      } catch (err) {
        console.log(err);
      }
    },

    // Device Group methods

    async createDeviceGroup() {
      if (this.groupName.length > 16) {
        this.groupError = "Group name must not exceed 16 characters.";
        return;
      }
      try {
        let response = await DeviceGroupService.createDeviceGroup({
          name: this.groupName,
        });
        console.log(response);
        this.groupSuccess = "Device group created successfully!";
        this.groupError = null;
        this.addDeviceSuccess = null;
        this.addDeviceError = null;
        this.removeDeviceSuccess = null;
        this.removeDeviceError = null;
        this.deleteGroupSuccess = null;
        this.deleteGroupError = null;
        this.getGroupDeviceGroups(this.groupId);
      } catch (err) {
        console.log(err);
        this.groupError = "Group creation failed!";
      }
    },

    async getGroupDeviceGroups(groupId) {
      try {
        let response = await DeviceGroupService.getGroupDeviceGroups({params: {groupId}});
        this.groupDeviceGroups = response.data;
        for (let i = 0; i < response.data.length; i++) {
          this.deviceGroups.push(response.data[i].name);
        }
      } catch (err) {
        console.log(err);
      }
    },

    async getDevicesFromGroup() {
      let refDeviceGroup;
      let self = this;

      for (let i = 0; i < this.groupDeviceGroups.length; i++) {
        if (this.groupName1 == this.groupDeviceGroups[i].name) {
          refDeviceGroup = this.groupDeviceGroups[i].id;
        }
      }
      try {
        let response = await DeviceGroupService.getDevicesFromGroup({
          params: { refDeviceGroup },
        });
        this.devicesFromGroup = response.data;
        this.devicesFromGroup.forEach(function (entry) {
          entry.createdAt = HelperMethods.formatTimestamp(entry["createdAt"]);
          entry.deviceName = HelperMethods.nameDevice(
            entry["refDevice"],
            self.$store.getters.devices
          );
        });
        console.log("response: ", response);
      } catch (err) {
        console.log(err);
      }
    },

    async addDeviceToGroup() {
      let refDeviceGroup;
      let deviceId = this.convertDeviceNameToId(String(this.deviceName4));

      for (let i = 0; i < this.groupDeviceGroups.length; i++) {
        if (this.groupName2 == this.groupDeviceGroups[i].name) {
          refDeviceGroup = this.groupDeviceGroups[i].id;
        }
      }
      let options = {
        refDeviceGroup: refDeviceGroup,
        refDevice: deviceId,
      };
      try {
        let response = await DeviceGroupService.addDeviceToGroup(options);
        console.log("response: ", response);
        this.addDeviceSuccess = response.data.message;
        this.groupSuccess = null;
        this.groupError = null;
        this.addDeviceError = null;
        this.removeDeviceSuccess = null;
        this.removeDeviceError = null;
        this.deleteGroupSuccess = null;
        this.deleteGroupError = null;
      } catch (err) {
        console.log(err);
        this.addDeviceError = "Device not found.";
      }
    },

    async removeDeviceFromGroup() {
      let refDeviceGroup;
      let deviceId = this.convertDeviceNameToId(String(this.deviceName5));

      for (let i = 0; i < this.groupDeviceGroups.length; i++) {
        if (this.groupName3 == this.groupDeviceGroups[i].name) {
          refDeviceGroup = this.groupDeviceGroups[i].id;
        }
      }
      let options = {
        refDeviceGroup: refDeviceGroup,
        refDevice: deviceId,
      };
      try {
        let response = await DeviceGroupService.removeDeviceFromGroup(options);
        console.log("response: ", response);
        this.removeDeviceSuccess = "Device removed from group.";
        this.groupSuccess = null;
        this.groupError = null;
        this.addDeviceSuccess = null;
        this.addDeviceError = null;
        this.removeDeviceError = null;
        this.deleteGroupSuccess = null;
        this.deleteGroupError = null;
      } catch (err) {
        console.log(err);
        this.removeDeviceError = "Could not remove the device.";
      }
    },

    async deleteDeviceGroup() {
      let refDeviceGroup;

      for (let i = 0; i < this.groupDeviceGroups.length; i++) {
        if (this.groupName1 == this.groupDeviceGroups[i].name) {
          refDeviceGroup = this.groupDeviceGroups[i].id;
        }
      }
      try {
        await DeviceGroupService.deleteDeviceGroup(refDeviceGroup);
        this.deleteGroupSuccess = "Group deleted.";
        this.groupSuccess = null;
        this.groupError = null;
        this.addDeviceSuccess = null;
        this.addDeviceError = null;
        this.removeDeviceSuccess = null;
        this.removeDeviceError = null;
        this.deleteGroupError = null;
      } catch (err) {
        console.log(err);
        if (this.$i18n.locale === "en") {
          this.deleteGroupError =
            "Could not delete the group. First remove all devices from the group.";
          this.deleteGroupSuccess = null;
          this.groupSuccess = null;
          this.groupError = null;
          this.addDeviceSuccess = null;
          this.addDeviceError = null;
          this.removeDeviceSuccess = null;
          this.removeDeviceError = null;
        } else if (this.$i18n.locale === "pt") {
          this.deleteGroupError =
            "Não é possivel eliminar o grupo. Primeiro remova todos os dispositivos do grupo.";
          this.deleteGroupSuccess = null;
          this.groupSuccess = null;
          this.groupError = null;
          this.addDeviceSuccess = null;
          this.addDeviceError = null;
          this.removeDeviceSuccess = null;
          this.removeDeviceError = null;
        }
      }
    },

    // RSSI Methods

    async saveRssiConfig() {

      let deviceId = this.convertDeviceNameToId(String(this.rssiSelectedDevice));
      let dataType = "rssi";
      this.rssiUpdateMessage = ""

      try {
        const response = await DeviceService.setDeviceRssi({
          deviceId: deviceId,
          dataType: dataType,
          value: this.rssiValue,
        });
        console.log("set rssi response: ", response);

        const response1 = await DeviceService.setRssiUpdatedStatus({
          refDevice: deviceId,
          status: false,
        });
        console.log("set status response: ", response1)
        this.rssiUpdateMessage = "RSSI value updated."
        
      } catch (err) {
        this.deviceError = "An error occurred while updating the offset.";
        console.log(err);
      }

    },

    async getDeviceRssi() {
      let self = this;
      let deviceId = this.convertDeviceNameToId(String(this.rssiSelectedDevice1));

      try {
        const response = await DeviceService.getDeviceOffsets(deviceId);
        this.deviceRssi = response.data;
        if (this.deviceRssi.length === 0) {
          return (this.deviceRssi = [
            {
              deviceName: this.rssiSelectedDevice1,
              rssi: null,
              updatedAt: null,
              createdAt: 0,
              rssiUpdated: false,
            },
          ]);
        } else {
          for (let i = 0; i < this.groupDevices.length; i++) {
            if (this.groupDevices[i].id == this.deviceRssi[0].refDevice) {
              self.deviceRssi[0].deviceName = self.groupDevices[i].name;
              break;
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    },

    async updateDeviceRssi() {
      let deviceId = this.convertDeviceNameToId(String(this.rssiSelectedDevice2));
      let deviceObj = HelperMethods.getDeviceObjByName(
        this.groupDevices,
        this.rssiSelectedDevice2
      );

      try {
        const response = await DeviceService.getDevice(deviceId);
        let mac = response.data.mac;

        const response1 = await DeviceService.getDeviceRssi(deviceId);
        let storedRssiConfig = response1.data[0];

        let rssiConfigMessage =
          HelperMethods.mqttUpdateRssiThresholdMessageBuilder(storedRssiConfig, mac);

        console.log("rssiConfigMessage: ", rssiConfigMessage);

        this.socket.emit("deviceSettings", {
          type: "updateDeviceRssiThreshold",
          data: {
            rssiThresholdConfigMessage: rssiConfigMessage,
            device: deviceObj,
          },
        });

        let response2 = await DeviceService.setRssiUpdatedStatus({
          refDevice: deviceId,
          status: true,
        });
        console.log("response2:", response2);

        setTimeout(this.waitLog, 1000);
        setTimeout(this.failureLog, 8000);

        // this.updateError = null;
      } catch (err) {
        this.updateError =
          "Device RSSI threshold update failed. <br/>Try again in a few moments.";
        console.log(err);
      }
    },

    navigateTo(route) {
      this.$router.push(route);
    },

    // Update Firmware methods

    async updateFirmware() {
      this.firmwareUpdateMessage = null;

      let deviceObj = HelperMethods.getDeviceObjByName(
        this.groupDevices,
        this.selectedDevice
      );

      let checkFirmwareVersionMessage =
        HelperMethods.mqttCheckFirmwareVersionMessageBuilder(deviceObj);
      let firmwareUpdateMessage =
        HelperMethods.mqttUpdateFirmwareMessageBuilder(deviceObj);

      this.socket.emit("deviceSettings", {
        type: "evaluateFirmwareVersion",
        data: {
          checkMessage: checkFirmwareVersionMessage,
          updateMessage: firmwareUpdateMessage,
          device: deviceObj,
        },
      });

      setTimeout(this.waitLog, 1000);
      setTimeout(this.failureLog, 8000);
    },

    clearLog() {
      this.firmwareUpdateMessage = null;
    },

    waitLog() {
      if (this.firmwareUpdateMessage === null) {
        this.firmwareUpdateMessage =
          "Wait a moment while the app communicates with the device.";
      } else return;
    },

    failureLog() {
      if (
        this.firmwareUpdateMessage ===
        "Wait a moment while the app communicates with the device."
      ) {
        this.firmwareUpdateMessage = "Device is busy. Try again in a minute.";
      } else return;
    },

    initSocket() {
      if (this.socket === null) {
        this.socket = io(`${config.socket.https == 0 ? "http" : "https"}://${config.socket.host}:${config.socket.port}`);
      } else return;
    },
  },

  mounted() {
    let self = this;

    this.initSocket();

    this.socket.on("deviceSettingsResponses", (data) => {
      this.firmwareUpdateMessage = data.message;
      console.log("SOCKET RESPONSE: ", data);
    });

    this.userInfo = this.$store.getters.userInfo;
    this.groupId = this.$store.getters.groupId;
    this.userServices = this.$store.getters.userServices;
    this.retrieveEntries();

    this.groupDevices = this.$store.getters.devices;
    this.groupDevices.forEach(function (device) {
      self.devices.push(device.name);
    });

    this.getGroupDeviceGroups(this.groupId);
  },

  beforeDestroy() {
    this.socket.disconnect();
    console.log("SOCKET DISCONNECTED: ", this.socket);
  },

  watch: {
    deviceName(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.deviceName.pop());
      }
    },
    deviceName1(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.deviceName1.pop());
      }
    },
    deviceName2(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.deviceName2.pop());
      }
    },
    deviceName3(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.deviceName3.pop());
      }
    },
    deviceName4(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.deviceName4.pop());
      }
    },
    deviceName5(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.deviceName5.pop());
      }
    },
    // groupName(val) {
    //   if (val.length > 1) {
    //     this.$nextTick(() => this.groupName.pop());
    //   }
    // },
    groupName1(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.groupName1.pop());
      }
    },
    groupName2(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.groupName2.pop());
      }
    },
    groupName3(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.groupName3.pop());
      }
    },
    dataType(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.dataType.pop());
      }
    },
    deviceId(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.deviceId.pop());
      }
    },
    firmwareUpdateMessage(val) {
      this.firmwareUpdateMessage = val;
    },
  },
};
</script>
